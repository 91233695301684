
// @ts-ignore
import DialogIcon from "@/components/Content/Dialogs/DialogIcon.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";

const downloadFiles: any = {
	creative_bulk: "/templates/template_massive_js.csv",
	creative_bulk_js: "/templates/template_creative_bulk_jstag.csv",
	pois_private: "/templates/privatepois.csv",
};

export default {
	name: "UploadInfo",
	components: { DialogIcon, CardActions, CardTextField },
	model: {
		prop: "model_data",
		event: "change",
	},
	props: {
		label: {
			type: String,
			default: "Upload",
		},
		layer_name: {
			type: String,
			default: "",
		},
		nameCountry: {
			type: String,
			default: "",
		},
		fileRequired: {
			type: Boolean,
			default: false,
		},
		btnClass: {
			type: String,
			default: "",
		},
		dataType: {
			type: String,
			default: "pois_private", // creative_bulk | creative_bulk_js | pois_private
		},
	},
	data: () => ({
		openDialog: false,
		openInfoDialog: false,
		valid: true,
		messages: null,
		loadingDownload: false,
		dataInfo: {},
	}),
	mounted() {},
	created() {
		this.$nextTick(() => {
			this.dataInfo = {
				creative_bulk: [
					this.$t("CreativeBulkFile.validate.file_js.validFileItem1"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem2"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem3"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem4"),
				],
				/*
				creative_bulk_js: [
					this.$t("CreativeBulkFile.validate.file_js.validFileItem1"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem2"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem3"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem4"),
					this.$t("CreativeBulkFile.validate.file_js.validFileItem5"),
				],*/
				pois_private: [
					this.$t("privatepois.validate.validFileItem1"),
					this.$t("privatepois.validate.validFileItem2"),
					this.$t("privatepois.validate.validFileItem3"),
					this.$t("privatepois.validate.validFileItem4"),
					this.$t("privatepois.validate.validFileItem5"),
				],
			};
		});
	},
	computed: {
		getItems() {
			if (!Object.keys(this.dataInfo).length) return [];
			const items: Array<string> = this.dataInfo[this.dataType];
			return items;
		},
		modelData: {
			set(val: any) {
				this.$emit("change", val);
			},
			get() {
				return this.model_data;
			},
		},
	},
	methods: {
		getHeigth(item: string): string{
			return item.length > 100 ? "50": "20";
		},
		handleToggleDialog(event: any) {
			this.openDialog = event;
		},
		handleAction(event: any) {
			switch (event.type) {
				case "save":
					break;

				case "close":
					this.openDialog = false;
					break;
			}
		},
		handleClear() {},
		handleUpload() {},
		downloadExample() {
			this.loadingDownload = true;
			window.location.href = downloadFiles[this.dataType];
			setTimeout(() => {
				this.loadingDownload = false;
			}, 250);
		},
	},
	watch: {},
};
