
import Vue from "vue";

export default Vue.extend({
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		btnText: {
			type: String,
			default: "Title",
		},
		customStyle: {
			type: String,
			default: "z-index: 9999;",
		},
		maxWidth: {
			type: Number,
			default: 0,
		},
	},
	components: {},
	data: () => ({}),
	created() {
		// this.$nextTick(() => {
		// 	console.log("Dialog::created", {
		// 		route_name: this.$route?.name,
		// 		dialog: this.dialog,
		// 	});
		// });
	},
	mounted() {},
	computed: {
		toggleDialog: {
			get() {
				return this.dialog;
			},
			set(val) {
				this.$emit("change", val);
			},
		},
		getMaxWidth() {
			if (this.maxWidth > 0) {
				return this.$vuetify.breakpoint.mobile
					? "auto"
					: `${this.maxWidth}px`;
			}
			return this.$vuetify.breakpoint.mobile ? "auto" : "1300px";
		},
	},
	methods: {},
	watch: {},
});
