import Vue from "vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import DividerForm from "@/components/Content/DividerForm.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { find, isEmpty, isUndefined } from "lodash";
import { isRequired, isDomain, isValidUrl } from "@/services/rule-services";
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import Separator from "@/components/Content/Separator.vue";
import { getError } from "@/utils/resolveObjectArray";
import UploadInfo from "@/components/Content/Commons/UploadInfo.vue";
import { TypeLoading } from "@/interfaces/loading";

//@ts-ignore
import { mapActions } from "vuex";
import {
	prepareCreateFormData,
	defaultBgColor,
	getAcceptFile,
	getBulkFilterOptions,
	getSupportedExt,
	initCreativeBulk,
	initResources,
	isValidAcceptFile,
} from "./utils";

export default Vue.extend({
	name: "CreativeBulk",
	props: {},
	components: {
		CardTextField,
		CardAutocomplete,
		DividerForm,
		CardSwitch,
		ArrayListItem,
		Separator,
		CardActions,
		UploadInfo
	},
	data: () => ({
		/**
		 * Form valid
		 */
		valid: true,

		/**
		 * Indica si el archivo es valido
		 */
		validFile: true,

		/**
		 * Evalua si el template seleccionado es = Banner Js Tag
		 */
		isJs: false,

		/**
		 * File accept
		 */
		acceptFile: "",
		
		/**
		 * Toggle Modal
		 */
		openUploadInfo: false,

		/**
		 * Model
		 */
		model: initCreativeBulk(),

		/**
		 * Filters & Options
		 */
		filters_options: getBulkFilterOptions(),

		/**
		 * Resources
		 */
		resources: initResources(),

		/**
		 * bg color
		 */
		bgColor: defaultBgColor,

		notAcceptedFile: {
			model: false,
			message: "",
		},
	}),
	created() {
		this.$nextTick(async () => {
			await this.setResourceProperties();
			this.fetchResource("in_banner_video_id", "creative_attributes");
			this.getFileAccept();
			//this.fetchResource("mime_type_id", "creative_attributes");
		});
	},
	mounted() {},
	beforeDestroy() {
	},
	computed: {
		getRules() {
			return {
				isRequired,
				isDomain,
				isValidUrl,
			};
		},
		stylesDrag() {
			return { "background-color": this.bgColor };
		},
		existsFile() {
			return "File" in window && this.model.file instanceof File;
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		getSupportedInfo() {
			return this.$t("CreativeBulkFile.validate.supported", {
				ext: getSupportedExt(this.isJs),
			});
		},
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),
		
		...mapActions("proccess", [
			"setLoadingField",
			"setNotification"
		]),

		...mapActions("advertiser", ["listCreativo"]),

		...mapActions("creative", [
			"mimeTypes",
			"inBannerVideos",
			"advertiserCategories",
			"addons",
			"createCreativeBulk",
		]),

		...mapActions("bulk", ["getResource"]),

		/**
		 * Update variables: resources, loading
		 * @param key
		 * @param resource
		 * @param nestedKey
		 * @param loading
		 * @returns
		 */
		async updateVarResource(
			key: any,
			resource: any,
			nestedKey: string = "",
			loading: Boolean = false
		) {
			let obj = this.resources[key];

			if (nestedKey) {
				obj = this.resources[nestedKey][key];
				this.resources[nestedKey][key] = Object.assign(obj, {
					loading: loading,
					items: loading ? [] : resource,
				});
				return;
			}

			this.resources[key] = Object.assign(obj, {
				loading: loading,
				items: loading ? [] : resource,
			});
		},

		/**
		 *
		 * fetch Resource
		 * @param key
		 * @param nestedKey
		 */
		async fetchResource(key: any, nestedKey: string = "") {
			let response: Array<any> = [];

			await this.updateVarResource(key, [], nestedKey, true);

			switch (key) {
				case "advertiser_id":
					response = await this.listCreativo(
						this.filters_options.filter_advertiser
					);
					await this.updateVarResource(
						"category_id",
						[],
						nestedKey,
						true
					);

					const categories = await this.advertiserCategories(
						this.filters_options.filter_advertiser
					);

					await this.updateVarResource(
						"category_id",
						categories,
						nestedKey,
						false
					);
					break;

				case "addons":
					response = await this.addons(
						this.filters_options.filter_creative_addon
					);
					break;

				// case "mime_type_id":
				// 	response = await this.mimeTypes();
				// 	const finded: any = find(response, function (f) {
				// 		return f.extra === "application/javascript";
				// 	});

				// 	this.model[nestedKey][key] = finded?.id;
				// break;

				case "in_banner_video_id":
					response = await this.inBannerVideos();
					const finded: any = find(response, function (f) {
						return f.value === "No video";
					});

					this.model[nestedKey][key] = finded?.id;
					break;

				default:
					response = await this.getResource({ key });
					break;
			}

			await this.updateVarResource(key, response, nestedKey, false);
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async dispathCreateCreativeBulk() {
			const preparedFormData = await prepareCreateFormData(this.model);
			await this.createCreativeBulk(preparedFormData);
		},

		async handleAppend(value: any, params: { key?: any }) {
			if (this.getRules.isValidUrl(value) === "Invalid domain.") return;
			switch (params.key) {
				case "pixel":
					this.addPixel(value);
					break;
				case "script":
					this.addScript(value);
					break;
			}
		},

		async handleDeletePixel(item: any) {
			if (
				this.model.creative_addon_settings.pixels.some(
					(v) => v === item
				)
			) {
				let indexItem = -1;
				this.model.creative_addon_settings.pixels.forEach(
					(element, index) => {
						if (element === item) {
							indexItem = index;
						}
					}
				);
				if (indexItem != -1) {
					this.model.creative_addon_settings.pixels.splice(
						indexItem,
						1
					);
				}
			}
		},

		async handleDeleteScript(item: any) {
			if (
				this.model.creative_addon_settings.scripts.some(
					(v) => v === item
				)
			) {
				let indexItem = -1;
				this.model.creative_addon_settings.scripts.forEach(
					(element, index) => {
						if (element === item) {
							indexItem = index;
						}
					}
				);
				if (indexItem != -1) {
					this.model.creative_addon_settings.scripts.splice(
						indexItem,
						1
					);
				}
			}
		},

		async addPixel(params: any) {
			if (isEmpty(params)) return;
			if (
				!this.model.creative_addon_settings.pixels.some(
					(v: any) => v == params
				)
			) {
				this.model.creative_addon_settings.pixels.push(params);
			}
			this.model.creative_addon_settings.tmp.pixels = null;
		},

		async addScript(params: any) {
			if (isEmpty(params)) return;
			if (
				!this.model.creative_addon_settings.scripts.some(
					(v: any) => v == params
				)
			) {
				this.model.creative_addon_settings.scripts.push(params);
			}
			this.model.creative_addon_settings.tmp.scripts = null;
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		removeFile() {
			this.model.file = null;
		},

		getNameFile() {
			if (!this.existsFile) return "";
			return this.model?.file?.name;
		},

		handleCancel() {
			this.$router.push({ name: "CreativesIndex" });
		},

		/**
		 * Setear los datos del advertiser seleccionado
		 * @param val
		 */
		async selectedAdvertiser(val: any) {
			let advertiser: any = null;

			if (val) {
				advertiser = find(
					this.resources.creative_advertiser.advertiser_id.items,
					function (a) {
						return a.id === val;
					}
				);
			}

			Object.assign(this.model.creative_advertiser, {
				domain: String(advertiser?.domain || ""),
				category_id: String(advertiser?.category_id || ""),
			});
		},

		/**
		 * Obtener las extensiones para validar el {accept:file}
		 */
		async getFileAccept() {
			const v = await getAcceptFile(this.isJs);
			this.acceptFile = v.toString();
		},

		/**
		 * Evento drop file
		 * @param event
		 * @returns
		 */
		async dropHandler(event: any) {
			this.bgColor = "#eee";
			if (!event) return;

			const file: any = event.dataTransfer.files[0];

			if (!(await isValidAcceptFile(file, this.isJs))) {
				this.notAcceptedFile = {
					model: true,
					message: this.$t("CreativeBulkFile.validate.supported", {
						ext: getSupportedExt(this.isJs),
					}),
				};
				return;
			}

			this.notAcceptedFile = {
				model: false,
				message: "",
			};

			this.model.file = file;
		},

		/**
		 * Evento dragover File
		 * @param event
		 * @returns
		 */
		dragOverHandler(event: any) {
			if (!event) return;
			this.bgColor = "#D1D1D1";
		},

		/**
		 * Evento drag leave file
		 * @param event
		 * @returns
		 */
		dragLeaveHandle(event: any) {
			if (!event) return;
			this.bgColor = "#eee";
		},

		showNotification(notification: Notification) {
			return this.setNotification(notification);
		},

		getMsgSuccess() {
			return {
				title: "Success",
				type: MessageTypes.SUCCESS,
				show: true,
				btn_text: "Aceptar",
				to: "CreativesIndex",
			};
		},

		/**
		 * VALIDATIONS
		 */

		/**
		 * Set Rules
		 * @param isReset
		 */
		async setRules(isReset: Boolean = false) {
			/**
			 * Prepare Rules
			 * @param isRequired
			 * @param isDomain
			 * @returns
			 */
			const getRules = (
				isRequired: Boolean = true,
				isDomain: Boolean = false
			) => {
				let rules: Array<any> = [];

				if (!isReset && isRequired) {
					rules.push(this.getRules.isRequired);
				}

				if (!isReset && isDomain) {
					rules.push(this.getRules.isValidUrl);
				}

				return { rules: rules };
			};

			// file
			Object.assign(this.resources.file, getRules());

			//await this.verifyFileValid();

			// creative_template_id
			Object.assign(this.resources.creative_template_id, getRules());

			// creative_advertiser
			Object.keys(this.resources.creative_advertiser).forEach((key) => {
				Object.assign(
					this.resources.creative_advertiser[key],
					getRules()
				);
			});

			// creative_ad_content
			Object.keys(this.resources.creative_ad_content).forEach((key) => {
				if (this.isJs) {
					// Add rules
				} else {
					if (!["click_url"].includes(key)) return;
					Object.assign(
						this.resources.creative_ad_content[key],
						getRules(true, true)
					);
				}
			});

			// creative_exchange_options
			Object.keys(this.resources.creative_exchange_options).forEach(
				(key) => {
					if (this.isJs) {
						// Add rules
					} else {
						if (!["landing_page_url"].includes(key)) return;
						Object.assign(
							this.resources.creative_exchange_options[key],
							getRules(
								false,
								!!this.model.creative_exchange_options
									.landing_page_url
							)
						);
					}
				}
			);
		},

		/**
		 * Handler Submit
		 * @param event
		 * @returns
		 */
		async handleSubmit(event: any) {
			try {
				await this.setRules();
				if (!(await this.verifyFileValid())) return;
				if (!(await this.validate())) return;

				await this.setLoadingData(TypeLoading.send);

				await this.dispathCreateCreativeBulk();
				
				await this.setLoadingData();
				// store action
			} catch (error) {
				console.error("handleSubmit", { error });
				await this.setLoadingData();
			}
		},

		/**
		 * Handler Btn Action
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "cancel":
					await this.setRules(true);
					await this.setLoadingData();
					this.handleCancel();
					break;
			}
		},

		/**
		 * Default resource data
		 * @returns
		 */
		resetResourceData() {
			return {
				show: true,
				loading: false,
				rules: [],
				items: [],
			};
		},

		/**
		 * Setear los {resources}
		 */
		async setResourceProperties() {
			let obj: any = this.resources;

			Object.entries(obj).forEach(([key, value]) => {
				const source: any = value;

				if (Object.keys(source).length > 0) {
					Object.entries(source).forEach(([key]) => {
						source[key] = this.resetResourceData();
					});
					return;
				}

				obj[key] = this.resetResourceData();
			});
		},

		/**
		 * Verificar si el archivo {file} es valido
		 */
		async verifyFileValid() {
			const isAcceptedFileValid = await isValidAcceptFile(
				this.model.file,
				this.isJs
			);

			this.validFile =
				isEmpty(this.resources?.file?.rules) || isAcceptedFileValid;

			return this.validFile;
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return {
				icon: "mdi-information-outline",
				text: params,
				to: "",
				right: params?.right ?? true,
			};
		},
	},

	watch: {
		async "model.creative_template_id"(val: any) {
			if (!val) {
				await this.setResourceProperties();
			}

			const templates: Array<any> =
				this.resources.creative_template_id.items;

			const template: any = templates.find((t) => t.id === val);

			/**
			 * id: 4
			 * Banner Js Tag
			 */

			this.isJs = template?.id == 4;

			this.model.file = null;

			await this.getFileAccept();

			await this.setRules(true);
		},

		async "model.creative_advertiser.advertiser_id"(val) {
			await this.selectedAdvertiser(val);
		},

		async "model.file"(val) {
			await this.getFileAccept();

			await this.verifyFileValid();

			const file: any = val;

			if (!file) return;

			if (!(await isValidAcceptFile(file, this.isJs))) {
				this.notAcceptedFile = {
					model: true,
					message: this.$t("CreativeBulkFile.validate.supported", {
						ext: getSupportedExt(this.isJs),
					}),
				};
				return;
			}

			this.notAcceptedFile = {
				model: false,
				message: "",
			};
		},
	},
});
